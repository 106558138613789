import { ReduxState } from '~/types/redux';
import { Address, AddressPredictive, AddressAllconnect } from '../types/address';

const addressFormatter = (address: string, address2?: string): string => {
  if (address && address2) {
    return `${address} - ${address2}`;
  }

  return address;
};

export const addressFormatPredictive = (address: AddressAllconnect): AddressPredictive => {
  const addr = {
    addressKey: address?.addressKey || '',
    addressLine1: address?.street_line,
    addressLine2: address?.secondary || '',
    city: address?.city,
    stateProvince: address?.state,
    zipCode: address?.zip || '',
    zipCode4: address?.zip4 || '',
    latitude: address?.point?.latitude || '',
    longitude: address?.point?.longitude || '',
    parsedAddress: {
      streetName: '',
      streetNumber: address?.primary || '',
      streetPostDirectional: address?.postDirection || '',
      streetPreDirectional: address?.preDirection || '',
      streetSuffix: '',
    },
  };
  return addr;
};

export const addressFormatAllconnect = (address: Address): AddressAllconnect => {
  const { addressKey, city, state, zip, zip4 = '', latitude, longitude, parsedAddress = {} } = address;
  const addr = {
    addressKey,
    city,
    isUnit: false,
    latitude: false,
    longitude: false,
    primary: parsedAddress?.streetNumber,
    secondary: address?.address2 || '',
    smartyStreetsMismatch: false,
    street_line: address?.address,
    street: `${parsedAddress?.streetName} ${parsedAddress?.streetSuffix}`,
    streetName: false,
    streetNumber: false,
    postDirection: parsedAddress?.streetPostDirectional || '',
    preDirection: parsedAddress?.streetPreDirectional || '',
    point: { latitude, longitude },
    state,
    unitType: false,
    unitValue: false,
    zip,
    zip4,
    zip9: `${zip}-${zip4}`,
    zip5: zip,
    zip9or5: `${zip}-${zip4}`,
    prettyAddress: `${address?.address}, ${city}, ${state} ${zip}-${zip4}`,
    hasFullAddress: true,
    addressUpdatedCount: 0,
  };
  return addr;
};

export const generateAllConnectAddressQueryParams = (
  address: ReduxState['address'],
  csid?: ReduxState['tracking']['clientSessionId']
): string => {
  const streetName = address?.install?.parsedAddress?.streetName ?? '';
  const streetSuffix = address?.install?.parsedAddress?.streetSuffix ?? '';
  const params = {
    street_line: address?.install?.address ?? '',
    primary: address?.install?.parsedAddress?.streetNumber ?? '',
    secondary: address?.install?.address2 ?? '',
    postDirection: address?.install?.parsedAddress?.streetPostDirectional ?? '',
    preDirection: address?.install?.parsedAddress?.streetPreDirectional ?? '',
    street: `${streetName} ${streetSuffix}`,
    city: address?.install?.city ?? '',
    state: address?.install?.state ?? '',
    zip: address?.install?.zip ?? '',
    point: JSON.stringify({
      latitude: address?.install?.latitude ?? '',
      longitude: address?.install?.longitude ?? '',
    }),
    ...(csid && { csid: csid ?? '' }),
  };
  if (address?.install?.zip4) params.zip = `${address?.install?.zip}-${address?.install?.zip4}`;

  const paramsString = Object.keys(params)
    .map((key) => key + '=' + encodeURIComponent(params[key]))
    .join('&');

  return paramsString;
};

export const generateRedirectProviderAddressQueryParams = (
  address: ReduxState['address'],
  csid?: ReduxState['tracking']['clientSessionId']
): string => {
  const params = {
    'ptv[street_line]': address?.install?.address ?? '',
    'ptv[city]': address?.install?.city ?? '',
    'ptv[state]': address?.install?.state ?? '',
    'ptv[zip]': address?.install?.zip ?? '',
    'ptv[parent_session]': csid ?? '',
    sub_id: '{click_id}',
  };
  if (address?.install?.zip4) params['ptv[zip]'] = `${address?.install?.zip}-${address?.install?.zip4}`;

  const paramsString = Object.keys(params)
    .map((key) => key + '=' + encodeURIComponent(params[key]))
    .join('&');

  return paramsString;
};

export default addressFormatter;
