import { useEffect } from 'react';

const Localize = (): JSX.Element => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://global.localizecdn.com/localize.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    const widget = document.createElement('script');

    script.onload = () => {
      widget.async = true;
      widget.defer = true;
      widget.innerHTML = `!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","getSourceLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);
      Localize.initialize({
      key: 'nH1lia9ugiilt',
      rememberLanguage: true
      });`;
    };

    document.body.appendChild(widget);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(widget);
    };
  }, []);

  return null;
};

Localize.displayName = 'Localize';

export default Localize;
