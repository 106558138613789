import React, { FC, useEffect, useState } from 'react';
import StickyBannerStyled from './StickyBannerStyled';
import Tooltip from '~/components/Tooltip';
import { InfoCircle, Stopwatch } from '~/components/Icons/outline';
import { iconMap } from '~/constants/iconMap';
import countdown from '~/helpers/daysCountDownTimer';

export interface StickyBannerProps {
  tooltipLegal?: string;
  icon?: string,
  endDate?: string;
  boldedCopy?: string;
  copy?: string;
  isBAU?: boolean;
}

const StickyBanner: FC<StickyBannerProps> = ({ tooltipLegal, icon, boldedCopy, copy, isBAU = false, endDate }) => {
  const Icon = iconMap[icon];
  const [time, setTime ] = useState(null);

  useEffect(() => {
    countdown(endDate, setTime);
  }, [endDate]);

  return (
    <StickyBannerStyled hasCountDown={endDate} isBAU={isBAU}>
      <div className="pmr-header__lto-banner">
        <div className={endDate && 'copy-container'}>
          <div  className='copy-left'>
            <p className='copy-left-elements'>
              {icon !== 'none' && endDate && (<Icon width="25px" height="25px" className='pmr-header__lto-banner-icon'/>)}
              {boldedCopy && <b>{boldedCopy}</b>} { isBAU ? <b className='copy-left-elements-copy'>{ copy !== 'none' && copy}</b> : (copy !== 'none' && copy) }
            </p>
          </div>
          <div className='copy-right'>
            {endDate && (
              <div className='copy-right-elements'>
                <p>
                  <Stopwatch width="25px" height="25px" fill="red" className='pmr-header__lto-banner-icon2'/>
                </p>
                <>
                  { time?.days !== 0 && <span className='copy-right-timeElement'>{ time?.days } { time?.days === 1 ? 'day' : 'days' } </span>}
                  <span className='copy-right-timeElement'>{ time?.hours } { time?.hours === 1 ? 'hour' : 'hours' } </span>
                  <span className='copy-right-timeElement'>{ time?.minutes } { time?.minutes === 1 ? 'minute' : 'minutes' } </span>
                  <span className='copy-right-timeElement'>{ time?.seconds } { time?.seconds === 1 ? 'second' : 'seconds' } </span>
                </>
              </div>
            )}
          </div>
        </div>
        {tooltipLegal && (
          <Tooltip theme="light" html={<div style={{ width: 300 }}>{tooltipLegal}</div>}>
            <InfoCircle width="16px" height="16px" />
          </Tooltip>
        )}
      </div>
    </StickyBannerStyled>
  );
};

StickyBanner.displayName = 'StickyBanner';

export default StickyBanner;
