import React, { SVGProps } from 'react';

const Bullet = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-bullet"
      {...props}
      >
    <circle cx="25" cy="25" r="20" fill="black"/>
    </svg>
  );
};

Bullet.displayName = 'Bullet';

export default Bullet;
