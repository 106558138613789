export const countdown = ( endDate: string, callback: (time: Record<string, number>) => void ): void => {
  const countdownTimer = setInterval( () => {
    const endDateTime = new Date(endDate).getTime();
    const now = new Date().getTime();
    const timeLeft = endDateTime - now;
    if ( timeLeft < 0 ) {
      callback( {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      } );
      clearInterval( countdownTimer );
      return;
    }

    const days = Math.floor( timeLeft / ( 1000 * 60 * 60 * 24 ) );
    const hours = Math.floor( ( timeLeft % ( 1000 * 60 * 60 * 24 ) ) / ( 1000 * 60 * 60 ) );
    const minutes = Math.floor( ( timeLeft % ( 1000 * 60 * 60 ) ) / ( 1000 * 60 ) );
    const seconds = Math.floor( ( timeLeft % ( 1000 * 60 ) ) / 1000 );

    callback( {
      days,
      hours,
      minutes,
      seconds
    } );
  }, 1000 );
};

export default countdown;
