import React, { SVGProps } from 'react';

const Clock = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" className="icon icon--clock" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7 3V8.41421L10.2929 11.7071L11.7071 10.2929L9 7.58579V3H7Z"
        fill="#FF0037"
      />
    </svg>
  );
};

Clock.displayName = 'Clock';

export default Clock;
