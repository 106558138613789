/* eslint-disable */
/**
 * Adobe Tracking service
 */

import { NETFLIX_BUNDLE } from '~/constants/entityIds';
import clientSide from '~/helpers/clientSide';

// Some local properties
const persistingVars = ['products', 'eVar49'];
const persist = {};
const allProducts = {
  plans: [],
  channels: [],
  extras: [],
  security: [],
};

let cartInstance = 'ftr';
// Check domain name or the path
if (clientSide) {
  if (window?.location?.href.indexOf('ftr-buy') !== -1) {
    cartInstance = 'ftr-buy';
  } else if (window?.location?.href.indexOf('internet.frontier') !== -1) {
    cartInstance = 'infr';
  } else if (window?.location?.href.indexOf('frontierinternet') !== -1) {
    cartInstance = 'frin';
  }
}

const buyFlow = cartInstance === 'ftr-buy' ? 'FTR Buy Flow' : 'RV Buy FLow';

// Sets Adobe into staging mode
export const onScriptLoad = (production) => {
  if (!production && clientSide) {
    window.localStorage.setItem('sdsat_stagingLibrary', true);
    window.localStorage.setItem('dtm_stagingLibraryFix', true);
    window._satellite.setDebug(true);
  }
};

// helper function for checking session storage
const isSessionStorageAvailable = () => {
  const test = 'test';
  try {
    window.sessionStorage.setItem(test, test);
    window.sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const updatePersistence = (obj) => {
  for (const x in obj) {
    if (persistingVars.indexOf(x) !== -1) {
      if (x !== 'products') {
        persist[x] = obj[x];
      }
    }
  }

  if (isSessionStorageAvailable()) {
    window.sessionStorage.setItem('persistTrack', JSON.stringify(persist));
  }
};

// Retrieves our persistence JSON string from sessionStorage
const getPersistence = () => {
  if (isSessionStorageAvailable()) {
    return JSON.parse(window.sessionStorage.getItem('persistTrack'));
  }
  return {};
};

// function to fire an adobe event
const fireAdobeEvent = ({ data, eventType, interaction }) => {
  updatePersistence(data);
  const p = getPersistence();
  for (const x in persistingVars) {
    if (Object.prototype.hasOwnProperty.call(p, persistingVars[x])) {
      // Don't update products property within the data object here if we're only trying to track a single product
      if (
        persistingVars[x] !== 'products' ||
        (persistingVars[x] === 'products' && (typeof data.singleProduct === 'undefined' || !data.singleProduct))
      ) {
        data[persistingVars[x]] = p[persistingVars[x]];
      }
    }
  }

  if (typeof window.s_dtm !== 'undefined' && typeof window.s_dtm.trackData === 'function') {
    window.s_dtm.trackData(data, eventType, interaction);
  }
};

const hashCode = (str) => {
  let hash = 0;
  let i = 0;
  let chr = 0;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return Math.abs(hash);
};

// Track pageview events
export const pageView = (e) => {
  //Don't track page view for confirmation page as it has its own event that registers as a page view
  if (e.detail.page === 'confirmation') return;
  // Make sure we have detail
  if (typeof e.detail !== 'undefined') {
    const d = e.detail;
    // Pull page off
    const page = d.page;
    // Make sure we have a page
    if (typeof d.page !== 'undefined') {
      const obj = {
        pageName: `${cartInstance}:order-online/${d.page}`,
        eVar33: e.detail.c2f ? `rv c2f` : buyFlow,
        eVar68: `${cartInstance}:rv ecom:${d.page}`,
        events: 'event68',
      };

      const ropeId = typeof e.detail.ropeId !== 'undefined' ? hashCode(e.detail.ropeId) : '';

      if (page === 'contact-credit') {
        obj.events += `,scCheckout:${ropeId}`;
        obj.products = e.detail.products;
        obj.eVar33 = buyFlow;
        const { internetId, lineItems } = e.detail;
        let productString = `;${internetId};;;;eVar43=pick your plan`;
        if (lineItems?.length > 0) {
          const items = Object.keys(lineItems);
          items.forEach((lineItemId) => {
            const item = lineItems[lineItemId];
            if (item.productId !== internetId) {
              productString += `;${item.productId};;;;eVar43=pick your plan`;
            }
          });
        }
        obj.products = productString;
      } else if (page === 'plan-package') {
        obj.eVar49 = 'in service area';
        obj.eVar50 = `rv ecom fuel:${e.detail.zip}`;
        obj.eVar65 = window?.location?.host || '';
        obj.eVar69 = e.detail.serviceability;
        obj.eVar70 = 'PreSale:No';
        obj.events += ',event30';
      } else if (page.includes('unserviceable')) {
        obj.eVar50 = `rv ecom fuel:${e.detail.zip}`;
        obj.eVar49 = 'not in service area';
        obj.events += ',event29,event30';
        obj.eVar69 = 'UNSERVICEABLE';
      } else if (page === 'verification') {
        if (e.detail.emailOptIn) {
          obj.events += ',event94';
        }
      }

      if (page !== 'order') {
        // Fire the page view event
        fireAdobeEvent({ data: obj });
      }
    }
  }
};

export const updateProductString = () => {
  let productString = '';

  // For each product type
  for (const x in allProducts) {
    // For each product
    productString += allProducts[x].join(',');
  }

  // Set the new products persistent string
  persist.products = productString;
};

export const updateProducts = (obj, type, productType) => {
  if (type === 'add') {
    // Plans will be replaced, anything else should be added (unless it's a duplicate)
    if (productType === 'plans') {
      allProducts[productType] = [obj];
    } else {
      const newProducts = obj.split(',');

      for (const x in newProducts) {
        let found = false;
        for (const y in allProducts[productType]) {
          if (allProducts[productType][y].indexOf(newProducts[x]) !== -1) {
            found = true;
          }
        }
        if (!found) {
          allProducts[productType].push(newProducts[x]);
        }
      }
    }

    // Update the product string
    updateProductString();
  }
};

export const removeProducts = (e) => {
  if (
    typeof e.detail !== 'undefined' &&
    typeof e.detail.productId !== 'undefined' &&
    typeof e.detail.productType !== 'undefined'
  ) {
    // Iterate over this product type in allProducts
    for (const x in allProducts[e.detail.productType]) {
      if (allProducts[e.detail.productType][x].indexOf(e.detail.productId)) {
        allProducts[e.detail.productType].splice(x, 1);
      }
    }
    updateProductString();
  }
};

// Track users that click "I'm moving to this address" on address/hadFrontier
export const movingToServiced = () => {
  const obj = {};
  obj.events = 'event96';

  fireAdobeEvent({
    data: obj,
    eventType: 'tl_o',
  });
};

// Tracks whenever a user adds a plan to cart
export const addedToCart = (e) => {
  if (
    typeof e.detail !== 'undefined' &&
    typeof e.detail.internetId !== 'undefined' &&
    typeof e.detail.newCart !== 'undefined'
  ) {
    const obj = {};
    obj.events = 'scAdd';
    if (e.detail.newCart) {
      obj.events += ',scOpen';
    }
    obj.products = '';
    obj.eVar33 = buyFlow;
    const { internetId, lineItems } = e.detail;

    let productString = `;${internetId};;;;eVar43=`;

    productString +=
      e?.detail?.netflixBundle === undefined
        ? 'pick your plan'
        : e.detail.netflixBundle
        ? 'Netflix Bundle Plans'
        : 'Fiber Plans';

    if (lineItems?.length > 0) {
      const items = Object.values(lineItems);
      const netflixProductExists = items.some((item) => item.productId === NETFLIX_BUNDLE);

      items.forEach((item) => {
        if (item?.category === 'Product' && item?.productId !== internetId) {
          const addOnDescription =
            e?.detail?.netflixBundle === undefined
              ? 'pick your plan'
              : netflixProductExists && e?.detail?.netflixBundle && item?.productId === NETFLIX_BUNDLE
              ? 'Netflix Bundle Plans'
              : 'VAS Add-On';

          productString += `,;${item.productId};;;;eVar43=${addOnDescription}`;
        }
      });
    }
    obj.products = productString;

    // Add flag for single product
    obj.singleProduct = true;
    updateProducts(obj.products, 'add', 'plans');
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
      interaction: 'cart addition',
    });
  }
};

// Tracks whenever a user adds an upsell plan to cart
export const upgradePlan = (e) => {
  if (typeof e.detail !== 'undefined' && typeof e.detail.products !== 'undefined') {
    const obj = {};
    obj.eVar43 = `${cartInstance} ecom fuel:upgrade plan`;
    obj.events = 'scAdd';
    obj.products = '';
    for (const x in e.detail.products) {
      obj.products += `;${e.detail.products[x]};;;;eVar43=${obj.eVar43}`;
      if (parseInt(x, 10) + 1 !== e.detail.products.length) {
        obj.products += ',';
      }
    }

    // Add flag for single product
    obj.singleProduct = true;

    updateProducts(obj.products, 'add', 'plans');
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
    });
  }
};

// Tracks whenever a user changes the channel types dropdown
export const channelType = (e) => {
  // Check for detail and productType
  if (typeof e.detail !== 'undefined' && typeof e.detail.channelType !== 'undefined') {
    const obj = {};
    obj.events = 'event14';
    obj.eVar14 = `rv ecom:add premium channels:${e.detail.channelType}`;
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
    });
  }
};

// Tracks whenever a user adds extras to their plan (equipment)
export const addedExtra = (e) => {
  if (typeof e.detail !== 'undefined' && typeof e.detail.extras !== 'undefined') {
    const obj = {};
    obj.eVar43 = `rv ecom fuel:add extras`;
    obj.events = 'scAdd';
    obj.products = '';
    for (const x in e.detail.extras) {
      obj.products += `;${e.detail.extras[x]};;;;eVar43=${obj.eVar43}`;
      if (parseInt(x, 10) + 1 !== e.detail.extras.length) {
        obj.products += ',';
      }
    }

    // Add flag for single product
    obj.singleProduct = true;

    updateProducts(obj.products, 'add', 'extras');
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
    });
  }
};

// Tracks whenever a user adds security to cart
export const addedSecurity = (e) => {
  if (typeof e.detail !== 'undefined' && typeof e.detail.security !== 'undefined') {
    const obj = {};
    obj.eVar43 = `${buyFlow}:add device protection`;
    obj.events = 'scAdd';
    obj.products = `;${e.detail.security};;;;eVar43=${obj.eVar43}`;

    // Add flag for single product
    obj.singleProduct = true;

    updateProducts(obj.products, 'add', 'security');
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
    });
  }
};

// Tracks when a user lands in cart
export const cartEntry = (e) => {
  // Check session uuid to ensure send the event only once
  const storedValue = sessionStorage.getItem('adobeCartEntry');
  if (!storedValue) {
    const obj = {};
    obj.events = 'event35';
    obj.eVar33 = buyFlow;
    obj.pageName = `${cartInstance}:order-online/address-check`;
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
      interaction: 'ecom flow start',
    });
    sessionStorage.setItem('adobeCartEntry', e?.detail?.clientSessionId);
  }
};

// Tracks when a user clicks call to order
export const callToOrder = () => {
  const obj = {};
  obj.eVar14 = `rv ecom:call to order click`;
  obj.events = 'event14';
  fireAdobeEvent({
    data: obj,
    eventType: 'tl_o',
    interaction: 'site interaction',
  });
};

// Tracks when a user gets hadfrontier or address suggestion
export const addressSuggestion = (e) => {
  const obj = {};
  obj.eVar50 = `${buyFlow}:${e.detail.zip}`;
  obj.events = 'event95';
  obj.eVar69 = e.detail.serviceability;
  fireAdobeEvent({ data: obj, eventType: 'tl_o' });
};

// Tracks whenever a credit check responds
export const creditCheckResponded = (e) => {
  if (typeof e.detail !== 'undefined' && typeof e.detail.creditCheckResponse !== 'undefined') {
    const obj = {};
    obj.events = 'event39';
    obj.eVar38 = e.detail.creditCheckResponse?.success === true ? 'credit check passed' : 'credit check not passed';
    fireAdobeEvent({ data: obj, eventType: 'tl_o' });
  }
};

// Tracks whenever a user submits their order
export const completeOrder = () => {
  const obj = {};
  obj.eVar14 = `rv ecom:complete order click`;
  obj.events = 'event14';
  fireAdobeEvent({
    data: obj,
    eventType: 'tl_o',
  });
};

// Tracks whenever a user hits "Back" or "Start Over"
export function navigateToPrevious(e) {
  if (typeof e.detail !== 'undefined' && typeof e.detail.navigationType !== 'undefined') {
    const obj = {};
    obj.eVar14 = `rv ecom:${e.detail.navigationType}`;
    obj.events = 'event14';
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
    });
  }
}

// Tracks whenever a user clicks the checkbox to accept terms and conditions on order page
export const acceptTerms = () => {
  const obj = {};
  obj.eVar14 = `rv ecom:accept t&cs`;
  obj.events = 'event14';
  fireAdobeEvent({
    data: obj,
    eventType: 'tl_o',
  });
};

// Tracks whenever any errors are thrown
export const trackError = (e) => {
  if (typeof e.detail !== 'undefined' && typeof e.detail.errorMessage !== 'undefined') {
    const obj = {};
    obj.eVar48 = e.detail.errorMessage;
    obj.events = 'event48';
    fireAdobeEvent({
      data: obj,
      eventType: 'tl_o',
      interaction: 'form errors',
    });
  }
};

// Tracks when popups are shown
export const trackPopup = (e) => {
  if (typeof e.detail !== 'undefined' && typeof e.detail.popupName !== 'undefined' && e.detail.page !== 'undefined') {
    const obj = {
      pageName: `${cartInstance}:order-online/${e.detail.page}:${e.detail.popupName}`,
      eVar33: e.detail.c2f ? `rv c2f` : `${buyFlow}`,
      eVar68: `${buyFlow}:${e.detail.page}:${e.detail.popupName}`,
      events: 'event68',
    };
    fireAdobeEvent({ data: obj });
  }
};

// Tracks whenever a user hits the confirmation page
export const orderConfirmation = (e) => {
  const {
    activationFee,
    vas,
    selectedAddons,
    deposit,
    useAutoPay,
    plans,
    monthlyRecurring,
    purchaseId,
    paperBill,
    daysUntilAppointment,
    outstandingBalance,
  } = e.detail;
  let productString = '';
  const balance = activationFee + deposit;
  const dueToday = balance <= 0 ? '0.00' : balance;

  if (typeof e.detail !== 'undefined' && typeof monthlyRecurring !== 'undefined' && typeof purchaseId !== 'undefined') {
    if (typeof e.detail.plans !== 'undefined') {
      // add selected product
      productString += `;${plans.productId};1;${dueToday};event31=${
        !useAutoPay ? plans.price.amountNoAutopay : plans.price.amount
      }`;
    }
    if (!vas && typeof selectedAddons !== 'undefined') {
      // Iterate over selectedAddons
      const addons = Object.keys(selectedAddons);
      addons.forEach((selectedAddonID) => {
        const addonId = selectedAddons[selectedAddonID];
        if (addonId.price.category === 'channel') {
          // track channel
          productString += `;${addonId.productId};1;${dueToday};event31=${addonId.price.amount}`;
        } else if (addonId.price.category === 'equipment' || 'secure' || 'Product') {
          // track equipment or secure
          productString += `;${addonId.productId};${
            typeof addonId.length !== 'undefined' ? addonId.length : 1
          };${dueToday};event31=${addonId.price.amount}`;
        }
      });
    }

    let additionalEvents = '';
    const obj = {};

    // deposit
    if ((deposit || outstandingBalance) != (undefined || 0)) {
      productString += `,;;;;`;
      const total = deposit + outstandingBalance;
      additionalEvents += ',event154';
      productString += `event154=${total}`;
    }

    // activationFee
    if (activationFee != (undefined || 0)) {
      productString += `,;;;;`;
      additionalEvents += ',event155';
      productString += `|event155=${activationFee}`;
    }

    // useAutoPay;
    if (useAutoPay) {
      additionalEvents += ',event119';
    }

    // usePaperlessBilling;
    if (!paperBill) {
      additionalEvents += ',event120';
    } else {
      additionalEvents += ',event20';
    }

    // Add discount price at the end
    persist.products = productString;

    // Build the tracking object
    obj.eVar33 = e.detail.c2f ? `rv c2f` : `${buyFlow}`;
    obj.eVar37 = `${daysUntilAppointment}`;
    obj.eVar68 = `:rv ecom:confirmation`;
    obj.events = `,purchase,event31${additionalEvents},event68`;
    obj.products = productString;
    obj.purchaseID = purchaseId;
    obj.pageName = `${cartInstance}:order-online/confirmation`;
    fireAdobeEvent({ data: obj });
  }
};

export const orderConfirmationVAS = (e) => {
  let additionalEvents = '';
  const { activationFee, deposit, lineItems, purchaseId } = e.detail;
  let productString = '';
  const obj = {};
  const balance = activationFee + deposit;
  const dueToday = balance <= 0 ? '0.00' : balance;

  // VAS line items
  if (lineItems?.length > 0) {
    const items = Object.keys(lineItems);
    items.forEach((lineItemId) => {
      const item = lineItems[lineItemId];
      productString += `;${item.productId};1;${dueToday};event31=${item.originalAmount ?? item.amount}`;
      if (item.originalAmount && item.amount) {
        const months = item.name.includes('YouTube') ? 12 : 3;
        const discount = (item.originalAmount - item.amount) * months;
        discount >= 0 && (productString += `|event32=${discount.toFixed(2)}`);
      }
      productString += ',';
    });
    productString += ',';
  }

  additionalEvents += `,event90:${purchaseId}`;
  persist.products = productString.replace(/ /g, '_').slice(0, productString?.length - 1);

  // Build the tracking object
  obj.events = `,event31${additionalEvents}`;
  obj.products = productString;
  obj.purchaseID = purchaseId;
  obj.pageName = `${cartInstance}:order-online/confirmation`;
  fireAdobeEvent({ data: obj, eventType: 'tl_o, VAS Order' });
};

export const trackFiloHelp = () => {
  const obj = {};
  obj.events = 'event79';
  obj.eVar79 = `rv ecom:filo customer service`;
  fireAdobeEvent({ data: obj });
};

export const displayModal = (e) => {
  const obj = {};
  obj.product = `rv:view-details:${e.detail.product}`;
  fireAdobeEvent({ data: obj });
};

export const editAddress = () => {
  const obj = {};
  obj.eVar14 = `${buyFlow}:service-check:update-address`;
  obj.events = 'event14';
  fireAdobeEvent({ data: obj });
};

export const depositRequired = () => {
  const obj = {};
  obj.eVar14 = `${buyFlow}:place-deposit`;
  obj.events = 'event14';
  fireAdobeEvent({ data: obj });
};

export const outstandingBalance = () => {
  const obj = {};
  obj.eVar14 = `${buyFlow}:pay-bill`;
  obj.events = 'event14';
  fireAdobeEvent({ data: obj });
};

export const depositOutstandingBalance = (e) => {
  const obj = {};
  obj.eVar14 = `${buyFlow}:place-deposit-pay-bill`;
  obj.events = 'event14';
  fireAdobeEvent({ data: obj });
};

export const toolTip = (e) => {
  const obj = {};
  obj.events = 'event14';
  obj.eVar14 = `rv ecom:tool-tip:${e.detail.text}`;
  fireAdobeEvent({ data: obj, eventType: 'tl_o' });
};

export const toggleBundles = (e) => {
  const obj = {};
  obj.events = 'event14';
  obj.eVar14 = e.detail.text;
  fireAdobeEvent({ data: obj, eventType: 'tl_o', interaction: 'swap plans shown' });
};

export const daysUntilFirstAvailable = (e) => {
  const obj = {};
  obj.pageName = `${cartInstance}:order-online/order`;
  obj.events = `,event24`;
  obj.eVar37 = `${e?.detail?.daysUntilFirstAvailable}`;
  fireAdobeEvent({ data: obj });
};

export const selectedInstallAppointment = (e) => {
  const obj = {};
  obj.events = `,event23`;
  obj.eVar37 = `${e?.detail?.selectedAppointment}`;
  fireAdobeEvent({ data: obj, eventType: 'tl_o' });
};
