// VAS
export const WIFI_SECURITY = '1e9899f8-ba18-4751-bc1d-e83d1b813507';
export const WHOLE_HOME_WIFI = '06cbe317-ccfd-461c-9504-df01e8a522da';
export const MY_PREMIUM_TECH_PRO = 'cf09e987-e30a-4c0b-ae68-bfc4344769da';
export const HOMESHIELD_ELITE = '24b9d45c-25ec-49fa-b896-ffa354ccbb82';
export const YTTV_BASIC = '3bdcc2d3-d9e5-4c7d-9ede-1e1e77aec404';
export const UNLIMITED_VOICE = 'ac0cc409-17d5-48ab-b12b-d5b6ce2322a3';
export const TOTAL_SHIELD = '2849c67b-4a32-4ca1-aebc-cf4b61d0e27e';
export const IDENTITY_PROTECTION = '792a5ad9-b779-4a6c-98bf-2eef56545a6b';
export const WIFI_SECURITY_PLUS = '9b8987b6-1c33-4af3-a0ce-5c3f642ad364';
export const UNBREAKABLE_WIFI = '5ea7fffd-efaa-4878-a5b7-a36419d536aa';
export const WHOLE_HOME_WIFI_PLUS = '2ac05012-5d1a-4d4f-ade7-89ee249a158a';
export const NETFLIX_BUNDLE = '66027766-735a-4e06-951e-234bacff458f';

// PAPER BILL FEE
export const PAPER_BILL_FEE = '46524048-3557-4a40-a5ba-5f16497c395e';

// INTERNET PRODUCTS
export const FIBER_7GIG = '4fbc7f33-616d-4923-bbb7-e88e46658917';

//ADDITIONAL EERO EXTENDER
export const EERO_MAX_7_EXTENDER = '366def79-dda3-46b6-9f28-37b9e0efb186';




