import styled, { css } from 'styled-components';

const StickyBannerStyled = styled.header.attrs({
  className: 'pmr-header',
})`

${({ theme, isBAU }) => {
  const { colors, media } = theme;

  return css`
    .pmr-header {

      &__lto-banner {
       ${

        isBAU ?

        `
        height: 65px;
        display: flex;
        flex-direction: row;
        padding: 8px 16px;
        background-color: ${colors.primary.darkBlue};
        color: ${colors.primary.white};
        font-size: 16px;
        line-height: 1.5;
        justify-content: center;
        gap: 8px;
        text-wrap: wrap;

        ${media.mobile.down}{
          height: 75px;
        }

        &-icon {
          color: ${colors.primary.red};
          margin-right: 15px;
          ${media.mobile.down}{
          margin-right: 5px;
        }
      }

        &-icon2 {
        display: flex;
        margin-right: 5px;
        ${media.mobile.down}{
          display: none;
        }
      }

        .copy-container {
          display: flex;
          justify-content: space-between;
          width: 1122px;

          ${media.mobile.down}{
            font-size: 12px;
            flex-direction: column;
          }
        }

        .copy-left {
          display: flex;
          ${media.mobile.down}{
            font-size: 12px;
          }

          &-elements {
            display: flex;
            align-items: center;

            ${media.mobile.down}{
            margin: 0 auto;
            }

            &-copy{
              ${media.tablet.down}{
                text-wrap: balance;
              }
              ${media.mobile.down}{
                  text-wrap: wrap;
                  text-align: center;
              }
            }
          }
        }

        .copy-right {
          display: flex;

          ${media.tablet.down}{
            width: 400px;
          }
          ${media.mobile.down}{
            width: auto;
          }

          &-elements {
            margin-left: auto;
            display: flex;
            align-content: center;
            align-items: center;

            ${media.tablet.down}{
              font-size: 12px;
              padding: 0 2px;
            }

            ${media.mobile.down}{
              margin: 0 auto;
            }
          }

          &-timeElement {
            padding: 0 5px;
            font-size: 16px;

            ${media.tablet.down}{
              font-size: 12px;
              padding: 0 2px;
            }
          }
        }

        .icon--outline-info-circle {
          display: inline-block;
        }}

        `
        :
        `
        display: flex;
          flex-direction: row;
          padding: 8px 16px;
          background-color: ${colors.primary.darkBlue};
          color: ${colors.primary.white};
          font-size: 0.625rem;
          line-height: 1.5;
          text-align: center;
          align-items: center;
          justify-content: center;
          gap: 8px;
          text-wrap: balance;
          .icon--outline-info-circle {
            display: inline-block;
          }

        `
      }
    }
  `;
}}
`;

export default StickyBannerStyled;
