import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useEffect } from 'react';
import { sanitizeNetworkData } from '~/helpers/sanitizeNetworkData';

function useLogRocket() {
  useEffect(() => {
    LogRocket.init('vuvnvg/frontier', {
      release: process.env.release,
      network: {
        requestSanitizer: sanitizeNetworkData(['addressKey']),
        responseSanitizer: sanitizeNetworkData(['addressKey']),
      },
      dom: {
        privateClassNameBlocklist: ['fs-mask'],
      },
    });

    setupLogRocketReact(LogRocket);
  }, []);
}

export default useLogRocket;
