import {
  FieldError,
  UseFormRegister,
  Control,
  UseFormGetValues,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';
import { Disclosure } from './order';
import { NoArgVoid } from './common';
import { Modals } from './products';
import { Address } from '~/types/address';
export interface ContactCreditReturnType {
  apiVersion: string;
  contactSubmitted: boolean;
  activeStep: number;
  activeModal: Modals;
  setActiveModal: (key?: Modals) => void;
  verifyPhone: {
    data: boolean;
    error: string | Error | VerifyPhoneResponse;
    loading: boolean;
    handleVerifyPhone: (data) => void;
  };
  formProps: {
    register: UseFormRegister<Contact & Credit>;
    setError: UseFormSetError<Contact & Credit>;
    clearErrors: UseFormClearErrors<Contact & Credit>;
    getValues: UseFormGetValues<Contact & Credit>;
    handleSubmit: NoArgVoid;
    handleSubmitGenerateCode: NoArgVoid;
    handleContactGenerateCode?: NoArgVoid;
    setValue: (name: string, value: string) => void;
    errors: ContactErrors;
    control: Control<Contact & Credit>;
    formValid: boolean;
    loading: boolean;
  };
  creditBypassSecondPass?: boolean;
  selectedPreviousAddress?: Address;
  setSelectedPreviousAddress?: (address: Address) => void;
}

export type ContactCreditRegister = UseFormRegister<Contact | Credit>;

// Contact
export type Contact = {
  accountUuid?: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  tcpaMarketing?: boolean;
  tcpaService?: boolean;
  gmtOffset?: number;
  depositOptedIn?: boolean;
};

export type ContactErrors = {
  firstname?: FieldError;
  lastname?: FieldError;
  email?: FieldError;
  phone?: FieldError;
};

export interface ContactResponse {
  success: boolean;
  contactVerifyComplete?: boolean;
}

// One Time Password
export type OneTimePassword = {
  success: boolean;
  codesGenerated: number;
  codeVerifyAttempts: number;
  verificationError: boolean;
  verificationRequiredFromQuote: boolean;
  attemptsPerCode: {
    1: 0;
    2: 0;
  };
};

export interface VerifyPhoneResponse {
  success: boolean;
}

// Credit
export type Credit = {
  month: number;
  day: number;
  year: number;
  ssn: string;
  previousAddress?: string;
};

export type ContactCredit = Contact & Credit;

export type UseFormClearCreditErrors<T> = (fields: (keyof T)[]) => void;

export type Configuration = {
  name: string;
  active: boolean;
};
export interface GetConfigurationsResponse {
  loading?: boolean;
  productId: string;
  configurations: Configuration[];
}

export type BillingSummary = {
  monthlyCharges: number;
  monthlyChargesACH: number;
  monthlyChargesDebit: number;
  monthlyChargesCredit: number;
  monthlyChargesNoAP: number;
  monthlyStrikethrough: number;
  oneTimeCharges: number;
  lineItems: BillingSummaryLineItem[];
  routerType: BillingSummaryLineItem[];
  totalUpfrontCharges: number;
  estimatedMonthlyTax: number;
  depositAmount: number;
  outstandingBalance: number;
  activationFee: BillingSummaryLineItem;
};

export type BillingSummaryLineItem = {
  amount: number;
  productId: string;
  name: string;
  unit: string;
  originalAmount?: number;
  displayName: string;
  category: string;
  displayStrikethrough: boolean;
};

export type IdentityQuestion = {
  question: string;
  questionNumber: number;
  answerChoices: Record<string, unknown>[];
};

export interface CreditResponse {
  success?: boolean;
  billingSummary?: BillingSummary;
  telephoneNumber?: string;
  disclosures?: Disclosure[];
  identityVerification?: IdentityQuestion[];
  frapiErrorCode?: string;
  frapiErrorMessage?: string;
  verifySubmitted?: boolean;
}

export type CreditErrors = {
  month?: FieldError;
  day?: FieldError;
  year?: FieldError;
  ssn?: FieldError;
  previousAddress?: FieldError;
};

export interface APIError {
  isHardStop: boolean;
  userErrorMessage: string;
  errorMessage?: string;
  acquisitionError?: string;
  frapiErrorCode?: string;
  frapiErrorMessage?: string;
  frapiErrorEndpoint?: string;
}

// Redux
export enum ActionTypes {
  SET_CONTACT = 'contact/set',
  SET_CREDIT = 'credit/set',
  SET_ONETIMEPASSWORD = 'otp/set',
}

// Actions
export interface SetContactAction {
  type: ActionTypes.SET_CONTACT;
  contact: Contact;
}

export interface SetCreditAction {
  type: ActionTypes.SET_CREDIT;
  credit: CreditResponse;
}

export interface SetOneTimePasswordAction {
  type: ActionTypes.SET_ONETIMEPASSWORD;
  otp: OneTimePassword;
}

export type Actions = SetContactAction | SetCreditAction | SetOneTimePasswordAction;
